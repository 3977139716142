export const BANKLIST = [
  {code:  'ICBC', name: '工商银行'},
  {code:  'ABC', name: '农业银行'},
  {code:  'CMB', name: '招商银行'},
  {code:  'CCB', name: '建设银行'},
  {code:  'CMBC', name: '民生银行'},
  {code:  'BOC', name: '中国银行'},
  {code:  'BCM', name: '交通银行'},
  {code:  'CIB', name: '兴业银行'},
  {code:  'CEB', name: '光大银行'},
  {code:  'GDB', name: '广东发展银行'},
  {code:  'PSBC', name: '邮政储蓄银行'},
  {code:  'CNCB', name: '中信银行'},
  {code:  'SPDB', name: '浦发银行'},
  {code:  'PAB', name: '平安银行'},
  {code:  'HXB', name: '华夏银行'},
  {code:  'ADBC', name: '农业发展银行'},
  {code:  'RCC', name: '农村信用社'},
  {code:  'CB', name: '商业银行'},
  {code:  'RCB', name: '农村商业银行'},
  {code:  'SHB', name: '上海银行'},
  {code:  'BOB', name: '北京银行'},
  {code:  'JSB', name: '江苏银行'},
  {code:  'BOCD', name: '成都银行'},
  {code:  'NJCB', name: '南京银行'},
  {code:  'HZB', name: '杭州银行'},
  {code:  'EGB', name: '恒丰银行'},
  {code:  'QDCCB', name: '青岛银行'},
  {code:  'BODL', name: '大连银行'},
  {code:  'GZCB', name: '广州银行'},
  {code:  'CZB', name: '浙商银行'},
  {code:  'CQCB', name: '重庆银行'},
  {code:  'TCCB', name: '天津银行'},
  {code:  'CBHB', name: '渤海银行'},
  {code:  'BSB', name: '包商银行'},
  {code:  'NCHCB', name: '南昌银行'},
  {code:  'NBCB', name: '宁波银行'},
  {code:  'HRBCB', name: '哈尔滨银行'},
  {code:  'HRXJB', name: '华融湘江银行'},
  {code:  'QLB', name: '齐鲁银行'},
  {code:  'MINTAIB', name: '民泰商行'},
  {code:  'SJB', name: '盛京银行'},
  {code:  'QSB', name: '齐商银行'},
  {code:  'DDB', name: '丹东商行'},
  {code:  'BOFS', name: '抚顺银行'},
  {code:  'FXB', name: '阜新银行'},
  {code:  'JZB', name: '锦州银行'},
  {code:  'BOLY', name: '辽阳银行'},
  {code:  'BOTL', name: '铁岭银行'},
  {code:  'BOYK', name: '营口银行'},
  {code:  'XTYB', name: '邢台商行'},
  {code:  'HDCB', name: '邯郸银行'},
  {code:  'HEBB', name: '河北银行'},
  {code:  'CZCCB', name: '沧州银行'},
  {code:  'LCCB', name: '廊坊银行'},
  {code:  'CDEB', name: '承德银行'},
  {code:  'PDSB', name: '平顶山银行'},
  {code:  'ZMDB', name: '驻马店银行'},
  {code:  'ZZB', name: '郑州银行'},
  {code:  'XXSSH', name: '新乡银行'},
  {code:  'BOLUOY', name: '洛阳银行'},
  {code:  'BOXC', name: '许昌银行'},
  {code:  'HKOUB', name: '汉口银行'},
  {code:  'CSCB', name: '长沙银行'},
  {code:  'DYCCB', name: '德阳银行'},
  {code:  'LSB', name: '莱商银行'},
  {code:  'WFCCB', name: '潍坊银行'},
  {code:  'YTAIB', name: '烟台银行'},
  {code:  'LSBC', name: '临商银行'},
  {code:  'DZB', name: '德州银行'},
  {code:  'JNB', name: '济宁银行'},
  {code:  'BORZ', name: '日照银行'},
  {code:  'LONGJB', name: '龙江银行'},
  {code:  'BOIMC', name: '内蒙古银行'},
  {code:  'FJHXB', name: '福建海峡银行'},
  {code:  'QZCCB', name: '泉州银行'},
  {code:  'XMCCB', name: '厦门银行'},
  {code:  'GZCCB', name: '赣州银行'},
  {code:  'JJCCB', name: '九江银行'},
  {code:  'SRB', name: '上饶银行'},
  {code:  'GYCCB', name: '贵阳银行'},
  {code:  'GUILB', name: '桂林银行'},
  {code:  'LZCCB', name: '柳州银行'},
  {code:  'BOBBG', name: '广西北部湾银行'},
  {code:  'HZCCB', name: '湖州银行'},
  {code:  'JXCCB', name: '嘉兴银行'},
  {code:  'JHCCB', name: '金华银行'},
  {code:  'SXCCB', name: '绍兴银行'},
  {code:  'TAIZB', name: '台州银行'},
  {code:  'WZCB', name: '温州银行'},
  {code:  'FUDB', name: '富滇银行'},
  {code:  'GDNYB', name: '广东南粤银行'},
  {code:  'DONGGB', name: '东莞银行'},
  {code:  'YCCCB', name: '宁夏银行'},
  {code:  'JSHB', name: '晋商银行'},
  {code:  'JCCB', name: '晋城银行'},
  {code:  'GSB', name: '甘肃银行'},
  {code:  'LZSB', name: '兰州银行'},
  {code:  'HSB', name: '徽商银行'},
  {code:  'JLB', name: '吉林银行'},
  {code:  'CCAB', name: '长安银行'},
  {code:  'XACB', name: '西安银行'},
  {code:  'CCQTGB', name: '重庆三峡银行'},
  {code:  'NCB', name: '宁波通商银行'},
  {code:  'ZHAOZB', name: '枣庄银行'},
  {code:  'BEEB', name: '鄞州银行'},
  {code:  'XTB', name: '邢台银行'},
  {code:  'KTHAIB', name: '泰京银行'},
  {code:  'SUZB', name: '苏州银行'},
  {code:  'BOQH', name: '青海银行'},
  {code:  'KLB', name: '昆仑银行'},
  {code:  'CHIYUB', name: '集友银行'},
  {code:  'HUBEIB', name: '湖北银行'},
  {code:  'BOHLD', name: '葫芦岛银行'},
  {code:  'HSHUIB', name: '衡水银行'},
  {code:  'ORDOSB', name: '鄂尔多斯银行'},
  {code:  'CHONGHINGB', name: '创兴银行'},
  {code:  'CHB', name: '朝兴银行'},
  {code:  'HBSB', name: '中原银行'},
  {code:  'CITIB', name: '花旗银行'},
  {code:  'HSBC', name: '汇丰银行'},
  {code:  'SCB', name: '渣打银行'},
  {code:  'HKBEA', name: '东亚银行'},
  {code:  'HANGSENGB', name: '恒生银行'},
  {code:  'DBS', name: '星展银行'},
  {code:  'WHBCN', name: '永亨银行'},
  {code:  'OCBC', name: '华侨银行'},
  {code:  'MSB', name: '摩根士丹利国际银行'},
  {code:  'JPMC', name: '摩根大通银行'},
  {code:  'WOORIB', name: '友利银行'},
  {code:  'NYCB', name: '南洋商业银行'},
  {code:  'UOB', name: '大华银行'},
  {code:  'HANAB', name: '韩亚银行'},
  {code:  'DEUTSCHE', name: '德意志银行'},
  {code:  'IBK', name: '企业银行'},
  {code:  'CMBCN', name: '华商银行'},
  {code:  'FSB', name: '华一银行'},
  {code:  'BNP', name: '法国巴黎银行'},
  {code:  'SOCIETE', name: '法国兴业银行'},
  {code:  'XIB', name: '厦门国际银行'},
  {code:  'CITIC', name: '中信嘉华银行'},
  {code:  'SHINHAN', name: '新韩银行'},
  {code:  'DAHSING', name: '大新银行'},
  {code:  'KEB', name: '外换银行'},
  {code:  'BANGKOKB', name: '盘谷银行'},
  {code:  'METROB', name: '首都银行'},
  {code:  'CTIF', name: '正信银行'},
  {code:  'CVBF', name: '村镇银行'},
  {code:  'TZBANK', name: '重庆渝北银座村镇银行'},
  {code:  'QJYZB', name: '重庆黔江银座村镇银行'},
  {code:  'SMYZB', name: '浙江三门银座村镇银行'},
  {code:  'JNYZB', name: '浙江景宁银座村镇银行'},
  {code:  'FTYZB', name: '深圳福田银座村镇银行'},
  {code:  'GZYZB', name: '江西赣州银座村镇银行'},
  {code:  'DYLSB', name: '东营莱商村镇银行'},
  {code:  'SYYZB', name: '北京顺义银座村镇银行'},
  {code:  'CGB', name: '广发银行'},
];
